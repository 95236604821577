import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { notification } from 'antd';
import React, { useEffect, useState } from 'react';
import { Cookies } from 'react-cookie';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { io } from 'socket.io-client';
import { useSession } from '../../../../context/SessionContext';
import { socket_listen_key, stripeLoadScript } from '../../../../environment';
import { appearance, currency } from '../../../../utils/commonUtils';
import strings from '../../../../utils/strings.json';
import Notify from '../../../common/notify';
import PaymentModal from '../../../modals/paymentModal/paymentModal';
import ThankyouModal from '../../../modals/thankyouModal/thankyouModal';
import FileConvertComponent from './filesConvert';
import {
  postUploadFile,
  saveFiles,
  fetchFileMetadata,
  downloadFile,
  appendFiles,
  uploadFile,
  saveAnalytics,
  paymentIntent,
  convertFile,
  checkSettingBanIp
} from '../../../../redux/slice';

const socket = io(socket_listen_key);

const stripePromise = loadStripe(stripeLoadScript);
const FileConvert = (props) => {
  const [isLoading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [move, setMove] = useState(false);
  const [percent, setPercent] = useState(0);
  const [clientSecret, setClientSecret] = useState('');
  const [customer, setCustomer] = useState('');
  const [checkoutModalOpen, setCheckoutModalOpen] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [openDropdowns, setOpenDropdowns] = useState({});
  const [uploadInProgress, setUploadInProgress] = useState(false); // New flag to track upload state
  const [convertOptions, setConvertOptions] = useState({});
  const [validationErrors, setValidationErrors] = useState({}); // State for validation errors
  const [limitExtendMessage, setLimitExtendMessage] = useState('');
  const dispatch = useDispatch();
  const {
    callpaymentIntent,
    callUploadFile,
    callSaveAnalytics,
    callConvertFiles,
    callFetchFileMetadata,
    callCheckSettingBanIp,
    callPostUpload,
    callSaveFile
  } = props;
  const cookies = new Cookies();
  const banIP = cookies.get('banned');
  const options = {
    clientSecret,
    appearance,
    locale: 'en'
  };
  const { sessionId } = useSession();
  const [conversionErrors, setConversionErrors] = useState({});
  const token = cookies.get('token');
  const [isShowThankyouPopup, setShowThankyouPopup] = useState(false);
  const navigate = useNavigate();
  const lang = cookies.get('lang') || 'en';
  const [searchParams] = useSearchParams();
  const [navigateUrl, setNavigateUrl] = useState(null); // Track the URL for navigation
  const [metadata, setMetadata] = useState([]);
  const searchNumber = Object.fromEntries([...searchParams]);
  const keys = Object.keys(searchNumber);
  const ipData = useSelector((state) => state.getIPIfy.getIPIfyData);
  const authenticate = useSelector((state) => state.authenticate);
  const { _id } = authenticate?.getAuthenticateData?.data || '';
  socket.emit('join', _id);

  useEffect(() => {
    const notificationData = (data) => {
      if (data.data.status === 'SUCCESS') {
        notification.success({
          message: data?.data?.message,
          placement: 'topRight',
          duration: 5
        });
      } else {
        notification.error({
          message: data?.data?.message,
          placement: 'topRight',
          duration: 5
        });
      }
    };
    socket.on('notification', notificationData);
    const statusUpdateHandler = (data) => {
      if (data?.data) {
        dispatch(appendFiles(data.data));
      }
    };

    socket.on('status-update', statusUpdateHandler);

    return () => {
      socket.off('notification', notificationData);
      socket.off('status-update', statusUpdateHandler);
    };
  }, [dispatch]);

  useEffect(() => {
    const interval = setInterval(() => {
      // setPercent((prev) => {
      //   if (prev < 100) {
      //     return prev + 1;
      //   } else {
      //     clearInterval(interval);
      //     return 150;
      //   }
      // });
    }, 150);

    return () => {
      clearInterval(interval);
      setMove(false);
    };
  }, []);

  useEffect(() => {
    if (percent === 100) {
      setMove(false);
    }
  }, [percent]);

  useEffect(() => {
    if (navigateUrl) {
      navigate(navigateUrl, { replace: true });
    }
  }, [navigateUrl, navigate]);

  // Payment and register function

  const dashRegister = async () => {
    setLoading(true);
    if (validateConvertToSelection()) {
      const newFiles = selectedFiles
        .filter((item) => item.status === 'success')
        .map((item) => ({
          url: item.url,
          convertFrom: item.convertedFrom,
          convertTo: item.convertedTo?.toLowerCase(),
          size: item.file.size,
          fileConvertId: item.fileConvertId,
          ...(item.file.name && { fileOriginalName: item.file.name })
        }));
      const requestData = {
        files: newFiles
      };
      try {
        let result = await callSaveFile(requestData);
        if (result?.type === 'saveFiles/fulfilled') {
          setSelectedFiles([]);
          const convertFilesResult = await callConvertFiles({ type: 'dashboard' });
          const { error: validationError, type: validationType, payload: convertPayload } = convertFilesResult || {};
          setLoading(true);
          if (validationType === 'convertFile/fulfilled') {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            Notify('success', convertPayload.message, '');
          } else {
            Notify('error', validationError.message, '');
          }
        }
      } catch (error) {
        Notify('error', error.message, '');
      } finally {
        setLoading(false);
      }
    }
  };

  const handleRegister = async () => {
    setLoading(true);
    if (validateConvertToSelection()) {
      const newFiles = selectedFiles
        .filter((item) => item.status === 'success')
        .map((item) => ({
          url: item.url,
          convertFrom: item.convertedFrom,
          convertTo: item.convertedTo?.toLowerCase(),
          size: item.file.size,
          fileConvertId: item.fileConvertId,
          ...(item.file.name && { fileOriginalName: item.file.name })
        }));
      const requestData = {
        sessionId: sessionId,
        files: newFiles
      };
      try {
        let result = await callSaveAnalytics(requestData);
        if (result?.type === 'saveAnalytics/fulfilled') {
          const intentdata = {
            amount: 50,
            currency: currency
          };
          if (result?.payload?.data?.success === false) {
            const errors = {};
            const filesToProcess = [...selectedFiles];
            result?.payload?.data.notAllowed.forEach(({ fileName, message }) => {
              const fileIndex = filesToProcess.findIndex((file) => file.file.name === fileName);
              if (fileIndex !== -1) {
                const fileWithError = filesToProcess[fileIndex];
                errors[fileWithError.id] = message;

                filesToProcess.splice(fileIndex, 1);
              }
            });
            setConversionErrors(errors);
            setPercent(false);
          } else {
            if (!token) {
              const paymentResult = await callpaymentIntent(intentdata);
              const { type, payload, error } = paymentResult || {};
              const currentPath = window.location.pathname;
              const checkIp = await callCheckSettingBanIp({ ipAddress: ipData?.ip });
              if (currentPath.includes('convert') && !keys.includes('off') && !keys.includes('clk')) {
                setCheckoutModalOpen(true);
                const updatedPath = currentPath.replace('/convert', '');
                // Only update the URL if it has changed
                if (currentPath !== updatedPath) {
                  window.history.pushState(null, '', updatedPath); // Modify the URL without refreshing
                }
              }
              if (type === 'paymentIntent/fulfilled') {
                setCustomer(payload.data.customer);
                setClientSecret(payload.data.client_secret);
                setCheckoutModalOpen(true);
                if (keys.includes('off')) {
                  const newParams = new URLSearchParams(searchParams);
                  newParams.delete('off');

                  if ([...newParams].length === 0) {
                    setNavigateUrl('/en/convert');
                  } else {
                    setNavigateUrl(`/${lang}/convert?${newParams.toString()}`);
                  }
                } else if (banIP || !checkIp?.payload?.data) {
                  setCheckoutModalOpen(true);
                  const updatedPath = currentPath.replace('/convert', '');
                  if (currentPath !== updatedPath) {
                    window.history.pushState(null, '', updatedPath); // Modify the URL without refreshing
                  }
                }
              } else {
                Notify('error', error.message, '');
              }
            }
          }
        } else {
          const errors = {};

          result?.payload?.data.notAllowed.forEach(({ fileName, fileExtension, fileConvertTo, message }) => {
            const fileWithError = selectedFiles.find(
              (file) =>
                file.file.name === fileName &&
                file.file.extension === fileExtension &&
                file.convertedTo?.toLowerCase() === fileConvertTo?.toLowerCase()
            );

            if (fileWithError) {
              errors[fileWithError.id] = message;
            }
          });
          setConversionErrors(errors);
          Notify('error', result?.error.message, '');
        }
      } catch (error) {
        Notify('error', error.message, '');
      } finally {
        setLoading(false);
      }
    }
  };

  // Create Common function for upload file to BE

  const uploadFileCommon = async (file, additionalData = {}) => {
    const formData = new FormData();
    formData.append('file', file);
    if (token) {
      formData.append('category', 'upload');
    } else {
      formData.append('sessionId', sessionId);
    }
    Object.entries(additionalData).forEach(([key, value]) => {
      formData.append(key, value);
    });
    try {
      const result = token ? await callPostUpload(formData) : await callUploadFile(formData);
      const { type, payload, error } = result || {};

      if (type?.endsWith('/fulfilled') && payload?.success) {
        return {
          success: true,
          data: {
            id: payload.data._id,
            file: {
              name: payload.data.fileOriginalName,
              size: payload.data.size
            },
            convertedFrom: payload.data.convertedFrom,
            fileConvertId: payload.data._id,
            status: strings.SUCCESS,
            percent: 100
          }
        };
      } else {
        return { success: false, error: error?.message || 'File upload failed' };
      }
    } catch (error) {
      console.error('Error in uploadFile:', error.message);
      return { success: false, error: error.message };
    }
  };

  //Delete file from the list

  const handleDeleteFile = (fileId) => {
    setLimitExtendMessage('');
    setSelectedFiles((prevFiles) => prevFiles.filter((fileObj) => fileObj.id !== fileId));
  };

  // Convert option select validation

  const validateConvertToSelection = () => {
    setLoading(false);

    const errors = {};
    selectedFiles.forEach((file) => {
      if (file.status === strings.SUCCESS && !convertOptions[file.id]) {
        errors[file.id] = 'Required';
      }
    });
    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  // Convert option selection

  const handleSelectConvertTo = (fileId, convertToOption) => {
    setConvertOptions((prev) => ({
      ...prev,
      [fileId]: convertToOption
    }));
    setValidationErrors((prev) => {
      const newErrors = { ...prev };
      delete newErrors[fileId];
      return newErrors;
    });
    const updatedFiles = selectedFiles.map((file) => {
      if (file.id === fileId) {
        return { ...file, convertedTo: convertToOption?.toLowerCase() };
      }
      return file;
    });

    setSelectedFiles(updatedFiles);
    setOpenDropdowns((prevState) => ({
      ...prevState,
      [fileId]: false
    }));
  };

  // Final file convert function

  const handleConvertFiles = async (url, onClose, setError) => {
    try {
      const convertFilesResult = await callConvertFiles({ sessionId: sessionId });
      const { type: validationType, error: validationError } = convertFilesResult || {};
      setLoading(true);
      if (validationType === 'convertFile/fulfilled') {
        setTimeout(() => {
          setLoading(false);
          navigate(`/${lang}/dashboard`);
        }, 6000);
      } else {
        setError(validationError.message);
      }
    } catch (error) {
      setError(error.message);
    }
  };

  // Fetch list of convert option

  const fileConvertData = async (type) => {
    setLoading(true);
    const metadata = await callFetchFileMetadata(type);
    try {
      if (metadata.type === 'files/fetchFileMetadata/fulfilled') {
        setMetadata(metadata.payload);
      }
    } catch (error) {
      Notify('error', error.message, '');
    } finally {
      setLoading(false);
    }
  };

  // Upload file progress and upload file

  const uploadFileHandler = async ({ file, downloadLink, fetchHeaders = {}, isBlob = false, preparedFile = null }) => {
    let simulatedPercent = 0;

    const interval = setInterval(() => {
      setSelectedFiles((prevFiles) =>
        prevFiles.map((f) =>
          f.id === file.id && simulatedPercent < 95 ? { ...f, percent: (simulatedPercent += 5) } : f
        )
      );
    }, 200);

    try {
      const fileToUpload =
        preparedFile ||
        (isBlob
          ? new File([file], file.name, { type: file.type })
          : new File([await (await fetch(downloadLink, { headers: fetchHeaders })).blob()], file.name, {
              type: file.type
            }));

      const uploadResult = await uploadFileCommon(fileToUpload);

      clearInterval(interval);

      if (uploadResult.success) {
        const { data } = uploadResult;
        setSelectedFiles((prevFiles) =>
          prevFiles.map((f) =>
            f.id === file.id
              ? {
                  ...f,
                  ...data,
                  percent: 100
                }
              : f
          )
        );
      } else {
        setSelectedFiles((prevFiles) =>
          prevFiles.map((f) => (f.id === file.id ? { ...f, status: uploadResult.error, percent: 100 } : f))
        );
      }
    } catch (error) {
      clearInterval(interval);
      setSelectedFiles((prevFiles) =>
        prevFiles.map((f) => (f.id === file.id ? { ...f, status: 'failed', percent: 100 } : f))
      );
    }
  };

  return (
    <>
      <FileConvertComponent
        move={move}
        setLoading={setLoading}
        isLoading={isLoading}
        percent={percent}
        setIsOpen={setIsOpen}
        isOpen={isOpen}
        handleRegister={handleRegister}
        checkoutModalOpen={checkoutModalOpen}
        clientSecret={clientSecret}
        options={options}
        stripePromise={stripePromise}
        customer={customer}
        setOpenDropdowns={setOpenDropdowns}
        openDropdowns={openDropdowns}
        selectedFiles={selectedFiles}
        handleDeleteFile={handleDeleteFile}
        setSelectedFiles={setSelectedFiles}
        validationErrors={validationErrors}
        handleSelectConvertTo={handleSelectConvertTo}
        limitExtendMessage={limitExtendMessage}
        conversionErrors={conversionErrors}
        setConversionErrors={setConversionErrors}
        setShowThankyouPopup={setShowThankyouPopup}
        onFileMetadata={fileConvertData}
        metadata={metadata}
        setUploadInProgress={setUploadInProgress}
        uploadInProgress={uploadInProgress}
        uploadFileCommon={uploadFileCommon}
        setLimitExtendMessage={setLimitExtendMessage}
        uploadFileHandler={uploadFileHandler}
        dashRegister={dashRegister}
      />
      {isShowThankyouPopup && (
        <ThankyouModal
          visible={isShowThankyouPopup}
          handleConvertFiles={handleConvertFiles}
          isLoading={isLoading}
          onClose={() => setShowThankyouPopup(false)}
        />
      )}
      {checkoutModalOpen && clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <PaymentModal
            clientSecret={clientSecret}
            checkoutModalOpen={checkoutModalOpen}
            setCheckoutModalOpen={setCheckoutModalOpen}
            customer={customer}
            setShowThankyouPopup={setShowThankyouPopup}
            setSelectedFiles={setSelectedFiles}
          />
        </Elements>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    callpaymentIntent: (data) => dispatch(paymentIntent(data)),
    callUploadFile: (data) => dispatch(uploadFile(data)),
    callSaveAnalytics: (data) => dispatch(saveAnalytics(data)),
    callConvertFiles: (data) => dispatch(convertFile(data)),
    callDownloadFiles: (data) => dispatch(downloadFile(data)),
    callFetchFileMetadata: (data) => dispatch(fetchFileMetadata(data)),
    callCheckSettingBanIp: (data) => dispatch(checkSettingBanIp(data)),
    callPostUpload: (data) => dispatch(postUploadFile(data)),
    callSaveFile: (data) => dispatch(saveFiles(data)),
  };
};

export default connect(null, mapDispatchToProps)(FileConvert);
