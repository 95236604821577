import React, { useState } from 'react';
import ModalListingComponent from './modalListing';
import { connect } from 'react-redux';
import { categories } from '../../../utils/commonUtils';
import { reconvertFiles } from '../../../redux/slice';

const ModalListing = (props) => {
  const {
    selectedFiles,
    setSelectedFiles,
    onCloseConvertPopUp,
    setSelectedRowKeys,
    isShowConvertPopup,
    setProcessing,
    callReconvertFile,
    onFileMetadata,
    metadata,
    isLoading,
    loading,
  } = props;
  const [fileErrors, setFileErrors] = useState({});
  const [modalConvertOptions, setModalConvertOptions] = useState({});
  const [modalOpenDropdowns, setModalOpenDropdowns] = useState({});
  const [convertFormat, setConvertFormat] = useState(null);
  const [convertAllChecked, setConvertAllChecked] = useState(false);

  const allFilesSameFormat = () => {
    if (selectedFiles.length === 0) {
      return true;
    }
    const firstFileCategory = Object.keys(categories).find((category) =>
      categories[category].includes(selectedFiles[0].convertForm)
    );

    if (!firstFileCategory) {
      return false;
    }
    return selectedFiles.every((file) => categories[firstFileCategory].includes(file.convertForm));
  };

  const handleConvertAllChange = (e) => {
    const isChecked = e.target.checked;
    setConvertAllChecked(isChecked);
    if (isChecked && convertFormat) {
      setSelectedFiles((prevFiles) =>
        prevFiles.map((file) => ({
          ...file,
          format: convertFormat
        }))
      );
      setModalConvertOptions(() => {
        const updatedOptions = {};
        selectedFiles.forEach((file) => {
          updatedOptions[file.key] = convertFormat.toLowerCase();
        });
        return updatedOptions;
      });
      setFileErrors({});
    } else {
      setSelectedFiles((prevFiles) =>
        prevFiles.map((file) => ({
          ...file,
          format: null
        }))
      );
      setModalConvertOptions(() => {
        const updatedOptions = {};
        selectedFiles.forEach((file) => {
          updatedOptions[file.key] = null;
        });
        return updatedOptions;
      });
      setConvertFormat('');
    }
  };

  const handleBatchConvert = async () => {
    const missingOptions = selectedFiles.filter((file) => !modalConvertOptions[file.key]);

    if (missingOptions.length > 0) {
      const errorState = missingOptions.reduce((acc, file) => {
        acc[file.key] = true;
        return acc;
      }, {});
      setFileErrors((prev) => ({ ...prev, ...errorState }));
      return;
    } else {
      const filesToConvert = selectedFiles.map((file) => ({
        id: file.key,
        convertTo: modalConvertOptions[file.key]
      }));

      onCloseConvertPopUp(false);
      setSelectedRowKeys([]);
      setSelectedFiles([]);
      setFileErrors({});

      try {
        const processingUpdates = filesToConvert.reduce((acc, file) => {
          acc[file.id] = true;
          return acc;
        }, {});
        setProcessing((prev) => ({ ...prev, ...processingUpdates }));
        const response = await callReconvertFile(filesToConvert);

        if (response.type === 'file/reconvertFiles/fulfilled') {
          setModalConvertOptions({});
          setConvertAllChecked(false);
        }
      } catch (error) {
        console.error('Batch conversion error:', error);
      } finally {
        const clearedProcessing = filesToConvert.reduce((acc, file) => {
          acc[file.id] = false;
          return acc;
        }, {});
        setProcessing((prev) => ({ ...prev, ...clearedProcessing }));
      }
    }
  };

  const toggleModalDropdown = (key) => {
    setModalOpenDropdowns((prevState) => ({
      ...prevState,
      [key]: !prevState[key]
    }));
  };

  const handleSelectModalConvertTo = (fileId, convertToOption) => {
    if (allFilesSameFormat()) {
      setConvertFormat(convertToOption);
    }
    setModalConvertOptions((prevOptions) => ({
      ...prevOptions,
      [fileId]: convertToOption?.toLowerCase()
    }));

    setFileErrors((prev) => {
      const updatedErrors = { ...prev };
      delete updatedErrors[fileId];
      return updatedErrors;
    });
    const allSameFormat = selectedFiles.every(
      (file) => modalConvertOptions[file.key] === convertToOption?.toLowerCase()
    );
    setConvertAllChecked(allSameFormat);
    toggleModalDropdown(fileId);
  };

  const handleDeleteSelectedFiles = (deleteFile) => {
    setSelectedFiles((prevSelectedFiles) => {
      const updatedFiles = prevSelectedFiles.filter((file) => file.key !== deleteFile);
      if (updatedFiles.length === 0) {
        onCloseConvertPopUp(false);
      }
      return updatedFiles;
    });

    setSelectedRowKeys((prevSelectedKeys) => prevSelectedKeys.filter((key) => key !== deleteFile));
  };

  return (
    <ModalListingComponent
      handleBatchConvert={handleBatchConvert}
      selectedFiles={selectedFiles}
      onCloseConvertPopUp={onCloseConvertPopUp}
      isShowConvertPopup={isShowConvertPopup}
      modalConvertOptions={modalConvertOptions}
      toggleModalDropdown={toggleModalDropdown}
      onFileMetadata={onFileMetadata}
      modalOpenDropdowns={modalOpenDropdowns}
      convertAllChecked={convertAllChecked}
      convertFormat={convertFormat}
      handleSelectModalConvertTo={handleSelectModalConvertTo}
      handleConvertAllChange={handleConvertAllChange}
      allFilesSameFormat={allFilesSameFormat}
      metadata={metadata}
      isLoading={isLoading}
      loading={loading}
      fileErrors={fileErrors}
      handleDeleteSelectedFiles={handleDeleteSelectedFiles}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.files.loading
  };
};

const mapDispatchToProps = (dispatch) => ({
  callReconvertFile: (data) => dispatch(reconvertFiles(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalListing);
