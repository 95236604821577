import { Checkbox, Table } from 'antd';
import React from 'react';
import { delete_file_icon, down_arrows, format_icon } from '../../../assets/images/dashboard';
import Modal from '../../../components/common/Modal/modal';
import { ConverterDropdown } from '../../../components/partials/homeComponent/FilesConvert/converterDropdown';
import strings from '../../../utils/strings.json';

const ModalListingComponent = (props) => {
  const {
    handleBatchConvert,
    isShowConvertPopup,
    onCloseConvertPopUp,
    selectedFiles,
    modalConvertOptions,
    toggleModalDropdown,
    onFileMetadata,
    modalOpenDropdowns,
    convertAllChecked,
    convertFormat,
    handleSelectModalConvertTo,
    handleConvertAllChange,
    allFilesSameFormat,
    loading,
    metadata,
    isLoading,
    fileErrors,
    handleDeleteSelectedFiles
  } = props;

  const TableFooter = () => (
    <div style={{ textAlign: 'center' }}>
      <div className='upload_btn' onClick={handleBatchConvert}>
        <a href='#'>Convert Files</a>
      </div>
    </div>
  );

  const convertColumns = [
    {
      title: 'File Type',
      dataIndex: 'fileType',
      key: 'fileType'
    },
    {
      title: 'Name',
      dataIndex: 'name',
      // sorter: true, // Enable sorting
      // onHeaderCell: () => ({
      //   onClick: () => onSortChange('name') // Toggle sort on header click
      // })
      render: (text) => (
        <div
          style={{
            overflow: 'hidden',
            display: '-webkit-box',
            WebkitLineClamp: 1,
            maxWidth: '143px',
            WebkitBoxOrient: 'vertical'
          }}
        >
          {text}
        </div>
      )
    },
    {
      title: 'Size',
      dataIndex: 'size'
    },
    {
      render: (_, record) => (
        <>
          <div
            className=''
            style={{
              display: 'flex',
              alignContent: 'center',
              gap: '30px',
              justifyContent: 'end',
              flexDirection: 'row'
            }}
          >
            <div
              className='file-option'
              style={{
                display: 'flex',
                alignContent: 'center',
                gap: '30px',
                justifyContent: 'end'
              }}
            >
              <div className='formate-info'>
                <button
                  className='convert-to-btn'
                  onClick={() => {
                    onFileMetadata(record.convertForm);
                    toggleModalDropdown(record.key, record.convertForm);
                  }}
                >
                  <img src={format_icon} alt='Format Icon' />
                  <span
                    style={{ textTransform: modalConvertOptions && modalConvertOptions[record.key] && 'uppercase' }}
                  >
                    {(modalConvertOptions && modalConvertOptions[record.key]) || strings.CONVERT_TO}
                  </span>
                  <img src={down_arrows} alt='' />
                </button>
                {fileErrors[record.key] && <p className='error-message'>{strings.NO_ALL_OPTION_SELECTED}</p>}
              </div>
              <button className='delete-file-btn-modal' onClick={() => handleDeleteSelectedFiles(record.key)}>
                <img src={delete_file_icon} alt='Delete File Icon' />
              </button>
            </div>
            {modalOpenDropdowns[record.key] && (
              <ConverterDropdown
                setIsOpen={() => toggleModalDropdown(record.key)}
                onSelectConvertTo={(option) => handleSelectModalConvertTo(record.key, option)}
                metadata={metadata}
                isLoading={isLoading}
              />
            )}
          </div>
        </>
      )
    }
  ];

  return (
    <Modal
      isOpen={isShowConvertPopup}
      onOk={isShowConvertPopup}
      onClose={() => onCloseConvertPopUp(false)}
      footer={null} // Remove default footer
      className='modal-content urL-modal'
    >
      {allFilesSameFormat() && (
        <Checkbox checked={convertAllChecked} onChange={handleConvertAllChange} disabled={!convertFormat}>
          Convert all as {convertFormat || 'Select Format'}
        </Checkbox>
      )}
      <Table
        columns={convertColumns}
        dataSource={selectedFiles}
        loading={loading}
        pagination={false}
        rowKey='key'
        footer={(selected) => <TableFooter selected={selected} />}
      />
    </Modal>
  );
};

export default ModalListingComponent;
