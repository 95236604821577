import React from 'react';

const SelectFilesComponent = (props) => {
  const { selectedFiles, handleDrop, handleFileChange } = props;

  return (
    <div className={`drag-drop-element ${selectedFiles?.length >= 20 ? 'no-pointer' : ''}`}>
      <div
        className='drop-area'
        onDrop={selectedFiles?.length <= 20 ? handleDrop : null}
        onDragOver={selectedFiles?.length <= 20 ? (event)=>event.preventDefault() : null}
        style={{
          borderRadius: '5px',
          padding: '20px',
          textAlign: 'center',
          cursor: selectedFiles?.length >= 20 ? 'default' : 'pointer'
        }}
      >
        <input
          type='file'
          id='drag_drop_file'
          multiple
          onChange={handleFileChange}
          disabled={selectedFiles?.length >= 20}
          style={{ display: 'none' }}
        />
        <label htmlFor='drag_drop_file' className={selectedFiles?.length >= 20 ? 'disable-button' : ''}>
          Drag & Drop files here to upload
        </label>
      </div>
    </div>
  );
};

export default SelectFilesComponent;
