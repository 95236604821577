import { LoadingOutlined } from '@ant-design/icons';
import { Dropdown, Menu, Spin, Table } from 'antd';
import React from 'react';
import { BsArrowRight } from 'react-icons/bs';
import {
  convert_icon,
  delete_button_icon,
  delete_file_icon,
  down_arrows,
  download_button_icon,
  download_file_icon,
  format_icon,
  more_icon,
  right_arrows,
  search_vector,
  upload_icon
} from '../../../assets/images/dashboard';
import FileIcon from '../../../components/fileIcon/fileIcon';
import { ConverterDropdown } from '../../../components/partials/homeComponent/FilesConvert/converterDropdown';
import { dashboardItems, formatFileSize, mobileOption } from '../../../utils/commonUtils';
import strings from '../../../utils/strings.json';
import { useSession } from '../../../context/SessionContext';

const DashFileListingComponent = ({
  metadata,
  isLoading,
  loading,
  paginationProps,
  data,
  onSortChange,
  setSelectedFiles,
  selectedFiles,
  onSearch,
  error,
  search,
  onFileMetadata,
  processing,
  selectedRowKeys,
  setSelectedRowKeys,
  onDownload,
  handleReconvert,
  handleDeleteSelectedFiles,
  handleConvert,
  handleSelectConvertTo,
  convertOptions,
  openDropdowns,
  toggleDropdown
}) => {
  const { getFocusModuleRef } = useSession();
  const fileOption = (record) => (
    <Menu>
      {mobileOption.map((item) => (
        <Menu.Item
          key={item.id}
          onClick={() => {
            if (item.name === 'Download') {
              onDownload([record.exportFileUrl]);
            } else {
              handleDeleteSelectedFiles(record.key);
            }
          }}
        >
          <div className='upload-files-option'>
            <img src={item.image} alt={item.name} style={{ width: 25 }} />
            <span>{item.name}</span>
          </div>
        </Menu.Item>
      ))}
    </Menu>
  );


  const handleFocus = (key) => {
    const ref = getFocusModuleRef(key);
    if (ref && ref.current) {
      ref.current.focus();
    }
  };
  const menu = (
    <Menu>
      {dashboardItems.map((item) => (
        <Menu.Item key={item.id} onClick={() => handleFocus(item.name)}>
          <div className='upload-files-menu'>
            <img src={item.image} alt={item.name} style={{ width: 32 }} />
            <span>{item.name}</span>
          </div>
        </Menu.Item>
      ))}
    </Menu>
  );

  const columns = [
    {
      title: 'File Type',
      dataIndex: 'fileType',
      key: 'fileType'
    },
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: true,
      onHeaderCell: () => ({
        onClick: () => onSortChange('name') //
      }),
      render: (text) => (
        <div
          style={{
            overflow: 'hidden',
            display: '-webkit-box',
            WebkitLineClamp: 1,
            maxWidth: '300px',
            WebkitBoxOrient: 'vertical'
          }}
        >
          {text}
        </div>
      )
    },
    {
      title: 'Size',
      dataIndex: 'size'
    },
    {
      title: () =>
        selectedFiles.length > 1 && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '15px',
              justifyContent: 'end'
            }}
          >
            <button className='document-default-btn convert-btn' onClick={() => handleReconvert()}>
              <img src={convert_icon} alt='Convert Icon' />
              Convert
            </button>
            <button className='document-default-btn download-btn' onClick={() => onDownload()}>
              <img src={download_button_icon} alt='Download Button Icon' />
              Download
            </button>
            <button className='document-default-btn delete-btn' onClick={() => handleDeleteSelectedFiles()}>
              <img src={delete_button_icon} alt='Delete Button Icon' />
              Delete
            </button>
          </div>
        ),
      render: (_, record) => (
        <div
          className=''
          style={{
            display: 'flex',
            alignContent: 'center',
            gap: '30px',
            justifyContent: 'end',
            flexDirection: 'row'
          }}
        >
          <div
            className='file-option'
            style={{
              display: 'flex',
              alignContent: 'center',
              gap: '30px',
              justifyContent: 'end'
            }}
          >
            <div className='file-type'>
              {record.convertedFileType}
              <span><img src={right_arrows} alt='' /></span>
            </div>
            <div className='formate-info'>
              {processing[record.key] ? (
                <button className='convert-file-btn disabled convert-to-btn'>
                  <span className='loader'>
                    <Spin indicator={<LoadingOutlined spin />} size='small' style={{ color: 'green' }} />
                  </span>
                  Converting...
                </button>
              ) : (
                <>
                  <button
                    className='convert-to-btn'
                    onClick={() => {
                      onFileMetadata(record.convertForm);
                      toggleDropdown(record.key, record.convertForm);
                    }}
                    disabled={record?.conversionStatus === 'FAILED'}
                  >
                    <img src={format_icon} alt='Format Icon' />
                    <span style={{ textTransform: convertOptions && convertOptions[record.key] && 'uppercase' }}>
                      {(convertOptions && convertOptions[record.key]) || strings.CONVERT_TO}
                    </span>
                    <img src={down_arrows} alt='' />
                  </button>
                  {convertOptions && convertOptions[record.key] && (
                    <button className='convert-file-btn convert-to-btn' onClick={() => handleConvert(record.key)}>
                      Convert
                      <BsArrowRight color={'#0076ce'} />
                    </button>
                  )}
                </>
              )}
            </div>
            <button
              className='download-file-btn'
              onClick={() => onDownload([record.exportFileUrl])}
              disabled={record?.conversionStatus === 'FAILED'}
            >
              <img src={download_file_icon} alt='Download File Icon' />
            </button>
            <button className='delete-file-btn' onClick={() => handleDeleteSelectedFiles([record.key])}>
              <img src={delete_file_icon} alt='Delete File Icon' />
            </button>
          </div>
          {openDropdowns[record.key] && (
            <ConverterDropdown
              setIsOpen={() => toggleDropdown(record.key)}
              onSelectConvertTo={(option) => handleSelectConvertTo(record.key, option)}
              metadata={metadata}
              isLoading={isLoading}
            />
          )}
          <div className='more-option'>
            <Dropdown
              overlay={fileOption(record)}
              trigger={['click']}
              placement='bottomRight'
              overlayclassNameName=' dashboard-header-menu dashboard-option-menu'
            >
              <img src={more_icon} alt='More' onClick={(e) => e.preventDefault()} />
            </Dropdown>
          </div>
        </div>
      )
    }
  ];

  const userListData = data?.data?.files?.map((item) => ({
    key: item._id,
    fileType: (
      <div style={{ width: '40px', height: '40px' }}>
        <FileIcon extension={item.convertedFileType ? item.convertedFileType : item.originalFileType} />
      </div>
    ),
    name: item.convertedFileName,
    size: formatFileSize(item.originalFileSize),
    exportFileUrl: item.exportFileUrl,
    convertForm: item.convertedFileType,
    conversionStatus: item.conversionStatus,
    convertedFileType:item.convertedFileType
  }));

  return (
    <>
      <section className='your_documents_sec'>
        <div className='main_container'>
          <div className='main_row'>
            <div className='main_col title_dock1'>
              <div className='section_title'>
                <h1>Your Documents</h1>
              </div>
            </div>
            <div className='main_col title_dock2'>
              <div className='search_bar'>
                <div className='file_search'>
                  <input
                    type='text'
                    name='Search'
                    value={search}
                    placeholder='Search...'
                    className='form-control'
                    onChange={(e) => onSearch(e.target.value)}
                  />
                  <div className='search-img'>
                    <img src={search_vector} alt='Search Icon' />
                  </div>
                </div>
              </div>
            </div>
            <div className='main_col title_dock3'>
              <Dropdown
                overlay={menu}
                trigger={['click']}
                placement='bottomRight'
                overlayclassNameName='upload-files-drop-box'
              >
                <div onClick={(e) => e.preventDefault()}>
                  <div className='upload_btn'>
                    <a href='#'>
                      <img src={upload_icon} alt='Upload Icon' className='upload-files-icon' />
                      Upload File
                    </a>
                  </div>
                </div>
              </Dropdown>
            </div>
          </div>
          <Table
            rowSelection={{
              selectedRowKeys,
              type: 'checkbox',
              onChange: (selectedKeys, selectedRows) => {
                setSelectedRowKeys(selectedKeys);
                setSelectedFiles(selectedRows);
              },
              getCheckboxProps: (record) => ({
                disabled: processing[record.key] || record?.conversionStatus === 'FAILED'
              })
            }}
            onRow={(record) => ({
              title: record?.conversionStatus === 'FAILED' ? 'This file has an error' : ''
            })}
            columns={columns}
            dataSource={userListData}
            loading={loading}
            pagination={paginationProps}
            rowKey='key'
            rowClassName={(record) => (processing[record.key] ? 'disabled-row' : '')}
          />
          {error && <p>Error: {error}</p>}
        </div>
      </section>
    </>
  );
};

export default DashFileListingComponent;
