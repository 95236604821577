import React, { useState } from 'react';
import { Input, Button, Spin } from 'antd';
import './urlModal.scss';
import strings from '../../../utils/strings.json';
import Modal from '../../common/Modal/modal';
import { isValidUrl } from '../../partials/homeComponent/FilesConvert/helper';

const UrlModal = ({ visible, onClose, handleGetURL, isLoading }) => {
  const [url, setUrl] = useState(''); // State to store input value
  const [error, setError] = useState(false); // State to store error message

  const handleChange = (e) => {
    setUrl(e.target.value); // Update state when input changes
    setError('');
  };
 
  const handleAddClick = () => {
    if (!url || url.trim() === '') {
      setError(strings.INVALID_URL);
      return;
    }
    if (!isValidUrl(url)) {
      setError(strings.URL_ERROR); // Show error if the URL is invalid
      return;
    }
    handleGetURL(url, onClose, setError, setUrl); // Pass the input value to handleGetURL when "Add" is clicked
    setError('');
  };
  const handleClose = () => {
    onClose();
    setUrl();
    setError('');
  };
  return (
    <Modal
      isOpen={visible}
      onOk={onClose}
      onClose={handleClose}
      footer={null} // Remove default footer
      className='modal-content urL-modal'
    >
      <Spin spinning={isLoading}>
        <div className='custom-modal-header'>
          <h2 className='custom-modal-title'>{strings.GET_URL}</h2>
        </div>
        <div className='custom-modal-content'>
          <Input
            placeholder={strings.URL_TEXT}
            value={url}
            onChange={handleChange}
            status={error ? 'error' : ''}
            className='custom-input'
          />
          {error && <div className='error-message'>{error}</div>}
          <div className='custom-button'>
            <Button type='primary' className='custom-ant-btn' onClick={handleAddClick}>
              {strings.ADD_BUTTON}
            </Button>
          </div>
        </div>
      </Spin>
    </Modal>
  );
};

export default UrlModal;
