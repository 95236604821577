import { Spin } from 'antd';
import './thankyouModal.scss';
import { Home } from '../../../pages';
import strings from '../../../utils/strings.json';
import thankyouAnimation from '../../animations/thankyouAnimation.json';
import Lottie from 'react-lottie';
import Modal from '../../common/Modal/modal';
import { arrow_right } from '../../../assets/images/home';

const ThankyouModal = ({ visible, handleConvertFiles, isLoading, onClose }) => {
  return (
    <div>
      <Home />
      <Modal
        isOpen={visible}
        onClose={onClose}
        className='thankyou-info-wrap'
        maskClosable={false}
        closeIcon={false}
      >
        <Spin spinning={isLoading}>
          <div className='thankyou-modal-section'>
            <div className='thankyou-modal-title'>
              <span> {strings.THANK_YOU} </span>
            </div>
            <div className='wrap-top-modal'>
              <div className='body-modal'>
                <div className='modal-thankyou-form'>
                  <div className='mb'>
                    <Lottie options={{ animationData: thankyouAnimation }} animation height={220} width={220} />
                  </div>
                </div>
                <div className='description-modal'>{strings.THANK_YOU_POPUP_INFORMATION_MESSAGE}</div>
                <div className='submit-btn-modal'>
                  <button className='hl_cta_wrap' onClick={handleConvertFiles}>
                    {strings.DASHBOARD_BUTTON} 
                    <img src={arrow_right} alt='arrow icon' className='arrow-icon' />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Spin>
      </Modal>
    </div>
  );
};

export default ThankyouModal;
