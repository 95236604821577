import React, { useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { Contact, Dashboard, Home, Pricing, Unsubscribe } from '../pages';
import Account from '../pages/account';
import Login from '../components/modals/loginModal';
import ConvertFile from '../pages/ConvertFile';
import Privacy from '../pages/Privacy';
import Terms from '../pages/Terms';
import PaymentModal from '../components/modals/paymentModal/paymentModal';
import Cookie from '../pages/Cookie';
import ResetPassword from '../components/modals/resetModal';
import { Cookies } from 'react-cookie';
import { privetRoute, publicRoute } from '../utils/commonUtils';
import { connect } from 'react-redux';
import { getIPIfy } from '../redux/slice';

const AppRoutes = (props) => {
  const location = useLocation();
  const navigate = useNavigate();

  const cookies = new Cookies();
  const token = cookies.get('token');
  const lang = cookies.get('lang');
  const currentRoute = location.pathname.split('/')[2];
  const {callGetIPIfy}=props
  useEffect(() => {
    if (token && (publicRoute.includes(currentRoute) || !currentRoute)) {
      navigate(`/${lang}/dashboard`);
    } else if (!token && privetRoute.includes(currentRoute)) {
      navigate(`/${lang}/`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
  const handleGetDetails = async () => {
    await callGetIPIfy();
  };

  useEffect(() => {
    handleGetDetails();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Routes>
      <Route path='/:lang/' element={<Home />} />
      <Route path='/:lang/contact' element={<Contact />} />
      <Route path='/:lang/dashboard' element={<Dashboard />} />
      <Route path='/:lang/pricing' element={<Pricing />} />
      <Route path='/:lang/unsubscribe' element={<Unsubscribe />} />
      <Route path='/:lang/account' element={<Account />} />
      <Route path='/:lang/login' element={<Login />} />
      <Route path='/:lang/payment' element={<PaymentModal />} />
      <Route path='/:lang/reset-password' element={<ResetPassword />} />
      <Route path='/:lang/convert' element={<ConvertFile />} />
      <Route path='/:lang/privacy' element={<Privacy />} />
      <Route path='/:lang/terms' element={<Terms />} />
      <Route path='/:lang/cookie' element={<Cookie />} />
    </Routes>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
   
    callGetIPIfy: () => dispatch(getIPIfy()),
   
  };
};

export default connect(null, mapDispatchToProps)(AppRoutes);

