import React, { createContext, useContext, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

const SessionContext = createContext();

export const useSession = () => useContext(SessionContext);

export const SessionProvider = ({ children }) => {
  const [sessionId,setSessionId] = useState(uuidv4());
  const generateNewSessionId = () => {
    setSessionId(uuidv4()); // Generates a new session ID
  };
  const focusModuleRef = useRef({}); // Dynamic object to store refs

  const moduleRef = (key, ref) => {
    focusModuleRef.current[key] = ref;
  };

  const getFocusModuleRef = (key) => focusModuleRef.current[key];

  return (
    <SessionContext.Provider value={{sessionId,generateNewSessionId,moduleRef, focusModuleRef,getFocusModuleRef}}>
      {children}
    </SessionContext.Provider>
  );
};
