import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Cookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import LoginModal from './loginModal';
import Notify from '../../common/notify';
import { validEmail } from '../../../utils/commonUtils';
import strings from '../../../utils/strings.json';
import { getAuthenticate, login } from '../../../redux/slice';

const Login = ({ callLoginData, getAuthenticate }) => {
  const cookies = new Cookies();
  const navigate = useNavigate();

  const [error, setErrors] = useState(null);
  const [emailError, setEmailErrors] = useState(null);
  const [passwordError, setPasswordError] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [lang] = useState(cookies.get('lang') || 'en');

  const validateEmail = (email) => {
    const isValid = validEmail.test(email);
    if (!email) {
      setEmailErrors(strings.PLEASE_ENTER_EMAIL);
      return;
    } else if (!validEmail.test(email)) {
      setEmailErrors(strings.INVALID_EMAIL_FORMAT);
      return;
    }
    return isValid;
  };

  const handleValuesChange = () => {
    setEmailErrors(null);
    setErrors(null);
  };

  const onClickOfContinue = async (values) => {
    if (!validateEmail(values.email.trim())) {
      return;
    }
    if (!values.password) {
      setPasswordError(strings.PLEASE_ENTER_PASSWORD);
      return;
    }
    try {
      setLoading(true);
      const loginData = {
        email: values.email.trim().toLowerCase(),
        password: values.password.trim(),
        provider: 'BASIC'
      };
      const result = await callLoginData(loginData);
      if (result?.type === 'login/fulfilled') {
        const { token, email, role } = result.payload.data;
        cookies.set('token', token);
        cookies.set('email', email);
        cookies.set('role', role);
        Notify('success', 'Successfully Login', '');
        navigate(`/${lang}/dashboard`);
        getAuthenticate();
      } else {
        setErrors(result?.error?.message || 'Login failed');
      }
    } catch (error) {
      setErrors(error.message || 'An error occurred during login');
    } finally {
      setLoading(false);
    }
  };

  return (
    <LoginModal
      onClickOfContinue={onClickOfContinue}
      handleValuesChange={handleValuesChange}
      loginError={error}
      emailError={emailError}
      setEmailErrors={setEmailErrors}
      passwordError={passwordError}
      setPasswordError={setPasswordError}
      strings={strings}
      isLoading={isLoading}
      passwordVisible={passwordVisible}
      setPasswordVisible={setPasswordVisible}
    />
  );
};

const mapStateToProps = (state) => ({
  loginData: state.loginData
});

const mapDispatchToProps = (dispatch) => ({
  callLoginData: (data) => dispatch(login(data)),
  getAuthenticate: () => dispatch(getAuthenticate())
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
