import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import AxiosInstance from '../../utils/axios';
export const sample = createAsyncThunk('sample', async () => {
  try {
    const response = await AxiosInstance.get(`/apiurl`);
    return response.data;
  } catch (error) {
    if (error.response) {
      throw error.response.data.message;
    } else {
      throw error;
    }
  }
});
const sampleSlice = createSlice({
  name: 'sample',
  initialState: {
    isLoading: false,
    validLocationKeyData: null,
    isError: false,
    errorMessage: ''
  },
  extraReducers: {
    [sample.pending]: (state) => {
      state.isLoading = true;
    },
    [sample.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.validLocationKeyData = action.payload;
      state.isError = false;
      state.errorMessage = '';
    },
    [sample.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.error.message;
    }
  }
});

export default sampleSlice.reducer;
