import React, { useEffect, useState } from 'react';
import AccountComponent from './account';
import { connect } from 'react-redux';
import Notify from '../../components/common/notify';
import strings from '../../utils/strings.json';
import { validateSpecialChar } from '../../utils/commonUtils';
import { useNavigate } from 'react-router-dom';
import { Cookies, useCookies } from 'react-cookie';
import { getAuthenticate, unsubscribe, updateProfile } from '../../redux/slice';

const Account = ({ callUpdateProfile, getAuthenticate, authenticate, callUnsubscribeData }) => {
  const [userDetails, setUserDetails] = useState({
    firstName: '',
    lastName: '',
    streetAddress: '',
    city: '',
    country: '',
    postalCode: ''
  });
  const [detailsErrors, setDetailsErrors] = useState({
    firstName: '',
    lastName: '',
    streetAddress: '',
    city: '',
    country: '',
    postalCode: ''
  });
  const [isSubscriptionModalOpen, setIsSubscriptionModalOpen] = useState(false);
  const navigate = useNavigate();
  const cookie = new Cookies();
  const [, , removeToken] = useCookies(['token']);
  const [, , removeRole] = useCookies(['role']);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!authenticate.getAuthenticateData) {
      getAuthenticate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (authenticate.getAuthenticateData) {
      setUserDetails({
        firstName: authenticate.getAuthenticateData.data?.firstName,
        lastName: authenticate.getAuthenticateData.data?.lastName,
        streetAddress: authenticate.getAuthenticateData.data?.streetAddress,
        city: authenticate.getAuthenticateData.data?.city,
        country: authenticate.getAuthenticateData.data?.country,
        postalCode: authenticate.getAuthenticateData.data?.postalCode
      });
    }
  }, [authenticate.getAuthenticateData]);

  const handleChangeDetails = (event) => {
    const { name, value } = event.target;
    if (!validateSpecialChar.test(value)) {
      setDetailsErrors((prev) => ({
        ...prev,
        [name]: strings.SPECIAL_CHARACTERS_ARE_NOT_ALLOWED
      }));
      return;
    }
    setUserDetails((prev) => ({
      ...prev,
      [name]: value
    }));
    setDetailsErrors((prev) => ({
      ...prev,
      [name]: ''
    }));
  };

  const handleUpdateProfile = async () => {
    const validationErrors = {};

    const validateField = (fieldName, errorMessage) => {
      if (!userDetails[fieldName]?.trim()) {
        validationErrors[fieldName] = errorMessage;
      }
    };

    // Perform validation
    validateField('firstName', 'Please Enter First Name');
    validateField('lastName', 'Please Enter Last Name');
    validateField('streetAddress', 'Please Enter Street Address');
    validateField('city', 'Please Enter City');
    validateField('country', 'Please Enter Country');
    validateField('postalCode', 'Please Enter Postal Code');

    // Check if there are validation errors
    if (Object.keys(validationErrors).length > 0) {
      setDetailsErrors(validationErrors); // Set the correct errors
      return;
    }
    try {
      const result = await callUpdateProfile(userDetails);
      if (result.type === 'updateProfile/fulfilled') {
        Notify('success', 'Billing Information Saved Successfully', '');
        getAuthenticate();
      } else if (result.error && result.error.message) {
        Notify('error', result.error.message, '');
      } else {
        Notify('error', 'An unexpected error occurred', '');
      }
    } catch (error) {
      Notify('error', error.message || 'An error occurred', '');
    }
  };
  const handleSubscribe = async (email) => {
    setIsLoading(true);
    try {
      const unsubscribeData = {
        email: email.trim().toLowerCase()
      };
      const result = await callUnsubscribeData(unsubscribeData);
      if (result?.type === 'unsubscribe/fulfilled') {
        Notify('success', result?.payload?.message || strings.UNSUBSCRIBED_SUCCESSFULLY, '');
        setTimeout(() => {
          removeToken(['token']);
          removeRole(['role']);
          const cookieKeys = Array.isArray(cookie.getAll()) ? cookie.getAll() : Object.keys(cookie.getAll());
          cookieKeys.forEach((key) => {
            cookie.remove(key, { path: '/' });
          });
          navigate('/');
          window.location.reload('/');
        }, 5000);
      } else {
        Notify('error', '', result?.error?.message || strings.UNSUBSCRIBE_FAILED);
      }
    } catch (error) {
      Notify(strings.UNSUBSCRIBE_FAILED);
    }finally {
      setIsLoading(false);
      setIsSubscriptionModalOpen(false);
    }
  };
  return (
    <AccountComponent
      userDetails={userDetails}
      handleChangeDetails={handleChangeDetails}
      handleUpdateProfile={handleUpdateProfile}
      detailsErrors={detailsErrors}
      authenticate={authenticate.getAuthenticateData}
      isSubscriptionModalOpen={isSubscriptionModalOpen}
      setIsSubscriptionModalOpen={setIsSubscriptionModalOpen}
      handleSubscribe={handleSubscribe}
      isLoading={isLoading}
    />
  );
};
const mapStateToProps = (state) => ({
  authenticate: state.authenticate
});

const mapDispatchToProps = (dispatch) => ({
  callUpdateProfile: (data) => dispatch(updateProfile(data)),
  getAuthenticate: () => dispatch(getAuthenticate()),
  callUnsubscribeData: (data) => dispatch(unsubscribe(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Account);
