import React, { useState } from 'react';
import UnsubscribeComponent from './unsubscribe';
import { connect } from 'react-redux';

import Notify from '../../components/common/notify';
import { validEmail } from '../../utils/commonUtils';
import strings from '../../utils/strings.json';
import { unsubscribe } from '../../redux/slice';

const Unsubscribe = ({ callUnsubscribeData }) => {
  const [isLoading, setLoading] = useState(false);
  const [error, setErrors] = useState(null);
  const [email, setEmail] = useState(null);

  const onSubscribe = async () => {
    try {
      if (!email) {
        setErrors(strings.PLEASE_ENTER_EMAIL);
        return;
      } else if (!validEmail.test(email)) {
        setErrors(strings.PLEASE_ENTER_VALID_EMAIL);
        return;
      }
      const unsubscribeData = {
        email: email.trim().toLowerCase()
      };
      setLoading(true);
      const result = await callUnsubscribeData(unsubscribeData);
      if (result?.type === 'unsubscribe/fulfilled') {
        Notify('success', result?.payload?.message || strings.UNSUBSCRIBED_SUCCESSFULLY, '');
      } else {
        setErrors(result?.error?.message || strings.UNSUBSCRIBE_FAILED);
      }
    } catch (error) {
      setErrors(strings.UNSUBSCRIBE_FAILED);
    } finally {
      setLoading(false);
    }
  };

  return (
    <UnsubscribeComponent
      onSubscribe={onSubscribe}
      setEmail={setEmail}
      email={email}
      isLoading={isLoading}
      error={error}
      setErrors={setErrors}
    />
  );
};

const mapDispatchToProps = (dispatch) => ({
  callUnsubscribeData: (data) => dispatch(unsubscribe(data))
});

export default connect(null, mapDispatchToProps)(Unsubscribe);
