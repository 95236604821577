import { Spin } from 'antd';
import strings from '../../../utils/strings.json';
import Modal from '../../common/Modal/modal';
import './subscriptionModal.scss';
const SubscriptionModal = ({ visible, onClose, handleSubscribe,isLoading }) => {
  return (
    <div>
      <Modal isOpen={visible} className='subscription-info-wrap' maskClosable={true} closeIcon={false} closable={false}>
      <Spin spinning={isLoading}>
        <div className='subscription-modal-section'>
          <div className='subscription-modal-title'>
            <span> {strings.CANCEL_SUBSCRIPTION} </span>
          </div>
          <div className='wrap-top-modal'>
            <div className='body-modal'>
              <div className='description-modal'>{strings.CONFIRM_MSG}</div>
              <div className='submit-btn-modal'>
                <button className='hl_cta_wrap' onClick={handleSubscribe}>
                  {strings.CONFIRM}
                </button>
              </div>
              <div className='submit-btn-modal'>
                <button className='cancel-btn-modal' onClick={onClose}>
                  {strings.CANCEL}
                </button>
              </div>
            </div>
          </div>
        </div>
        </Spin>
      </Modal>
    </div>
  );
};

export default SubscriptionModal;
