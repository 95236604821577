import React, { useState } from 'react';
import OneDriveComponent from './oneDrive';
import strings from '../../../../../utils/strings.json'
import { isValidFilesize } from '../helper';
import { OneDriveClientId } from '../../../../../environment';

const OneDrive = (props) => {
  const { setUploadInProgress,setSelectedFiles,uploadFileHandler,onDismiss,setLimitExtendMessage} = props;
  // Onedrive integration
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);

  const {port,pathname,hostname,protocol}=window.location
  
  const redirectUri = hostname ==='localhost' ? `${protocol}//${hostname}:${port}${pathname}` : `${protocol}//${hostname}${pathname}`;
  

  const loadOneDriveScript = () => {
    return new Promise((resolve, reject) => {
      if (isScriptLoaded) {
        resolve();
        return;
      }
      const script = document.createElement('script');
      script.src = 'https://js.live.net/v7.2/OneDrive.js';
      script.type = 'text/javascript';
      script.async = true;

      script.onload = () => {
        setIsScriptLoaded(true);
        resolve();
      };

      script.onerror = () => {
        reject(new Error('Failed to load OneDrive script'));
      };
      document.body.appendChild(script);
    });
  };

  const openOneDrive = () => {
    loadOneDriveScript()
      .then(() => {
        window.OneDrive.open({
          clientId: OneDriveClientId?.replace(/['",]/g, ''),
          action: 'share',
          multiSelect: true,
          openInNewWindow: true,
          advanced: {
            redirectUri: redirectUri
          },
          success: (response) => {
            handleOneDriveFileSuccess(response.value);
          },
          error: (error) => {
            console.error('Error:', error);
          }
        });
      })
      .catch((error) => {
        console.error('Error loading OneDrive script:', error);
      });
  };

  const handleOneDriveFileSuccess = async (files) => {
    if (files.length === 0) {
      return;
    }
    const newFiles= isValidFilesize(files);
    setSelectedFiles((prevFiles) => {
      const totalFiles = prevFiles.length + newFiles.length;

      if (totalFiles > 20) {
        setLimitExtendMessage(strings.FILE_LIMIT_ERROR_MESSSGE);
        const availableSlots = 20 - prevFiles.length;
        return [...prevFiles, ...newFiles.slice(0, availableSlots)];
      }

      setLimitExtendMessage('');
      return [...prevFiles, ...newFiles];
    });
    setUploadInProgress(true);
    for (let file of files) {
      await getOneDriveFiles(file);
    }
    setUploadInProgress(false);
  };

  const getOneDriveFiles = async (file) => {
    await uploadFileHandler({
      file,
      downloadLink: file['@microsoft.graph.downloadUrl'],
    });
  };
  
  return <OneDriveComponent openOneDrive={openOneDrive} onDismiss={onDismiss} />;
};

export default OneDrive;
