import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AxiosInstance from '../../utils/axios';
export const fetchFiles = createAsyncThunk(
  'files/fetchUserFiles',
  async ({ page, limit, sort, search }, { rejectWithValue }) => {
    try {
      const params = new URLSearchParams();
      if (page !== undefined && page !== null) {
        params.append('page', page);
      }
      if (limit !== undefined && limit !== null) {
        params.append('limit', limit);
      }
      if (sort !== undefined && sort !== null) {
        params.append('sort', JSON.stringify(sort));
      }
      if (search !== undefined && search !== null) {
        params.append('search', search);
      }
      const queryString = params.toString() ? `?${params.toString()}` : '';
      const response = await AxiosInstance.get(`file/user-files${queryString ? queryString : ''}`);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error.message);
    }
  }
);

export const appendFiles = createAsyncThunk('appendFiles', async (data) => {
  return { data };
});

const userListingSlice = createSlice({
  name: 'files',
  initialState: {
    data: null,
    loading: false,
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchFiles.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchFiles.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchFiles.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(appendFiles.fulfilled, (state, action) => {
        if (state.data?.data?.files) {
          state.data.data.files = [action.payload.data, ...state.data.data.files];
          state.data.data.metaData.total += 1;
        } else {
          state.data = {
            data: {
              files: [action.payload.data]
            }
          };
        }
      });
  }
});

export default userListingSlice.reducer;
