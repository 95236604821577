/* eslint-disable react/display-name */
import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { one_drive } from '../../../../../assets/images/dashboard';
import strings from '../../../../../utils/strings.json';
import { useSession } from '../../../../../context/SessionContext';

const OneDriveComponent = forwardRef((props, ref) => {
  const { openOneDrive, onDismiss, uniqueKey = 'One Drive' } = props;
  const buttonRef = useRef(null);
  const { moduleRef } = useSession();

  // Expose focus to parent via useImperativeHandle
  useImperativeHandle(ref, () => ({
    focus: () => {
      if (buttonRef.current) {
        buttonRef?.current?.focus();
      }
    }
  }));

  useEffect(() => {
    moduleRef(uniqueKey, buttonRef);
  }, [uniqueKey, buttonRef]);

  return (
    <div
      className='device-icon-inner'
      onClick={() => (onDismiss ? null : openOneDrive())}
      style={{ cursor: onDismiss ? 'default' : 'pointer' }}
      ref={buttonRef}
      tabIndex={0}
    >
      <img src={one_drive} alt='Box' />
      <span>{strings.ONE_DRIVE}</span>
    </div>
  );
});

export default OneDriveComponent;
