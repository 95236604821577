/* eslint-disable react/display-name */
import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { drive_icon } from '../../../../../assets/images/dashboard';
import { useSession } from '../../../../../context/SessionContext';

const GoogleDriveComponent = forwardRef((props, ref) => {
  const { handleOpenPicker, onDismiss,uniqueKey= "Google Drive" } = props;
  const buttonRef = useRef(null);
  const { moduleRef } = useSession();

  // Expose focus to parent via useImperativeHandle
  useImperativeHandle(ref, () => ({
    focus: () => {
      if (buttonRef.current) {
        buttonRef.current.focus();
      }
    }
  }));

  useEffect(() => {
    moduleRef(uniqueKey, buttonRef);
  }, [uniqueKey, buttonRef]);
  return (
    <div
      className='device-icon-inner'
      onClick={onDismiss ? null : handleOpenPicker}
      style={{ cursor: onDismiss ? 'default' : 'pointer' }}
      ref={buttonRef}
      tabIndex={0}
    >
      <img src={drive_icon} alt='Google Drive' />
      <span>Google Drive</span>
    </div>
  );
});

export default GoogleDriveComponent;
