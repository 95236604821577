import React from 'react';
import {
  compress_pdf_icon,
  compress_pdf_right_icon,
  edit_pdf_icon,
  edit_pdf_right_icon,
  extract_pdf_icon,
  extract_pdf_right_icon,
  merge_pgf_icon,
  merge_pgf_right_icon,
  protect_pdf_icon,
  protect_pdf_right_icon,
  remove_pdf_icon,
  remove_pdf_right_icon,
  rotate_pdf_icon,
  rotate_pdf_right_icon,
  sign_pdf_icon,
  sign_pdf_right_icon,
  split_pdf_icon,
  split_pdf_right_icon,
  to_word_pdf_icon,
  to_word_pdf_right_icon,
  unlock_pdf_icon,
  unlock_pdf_right_icon,
  word_pdf_icon,
  word_pdf_right_icon
} from '../../assets/images/dashboard';
import FileConvert from '../../components/partials/homeComponent/FilesConvert';
import './dashboard.scss';
import DashFileListing from '../../pages/dashboard/dashListing/index';
import ModalListing from './modalListing';

const DashboardComponent = ({
  onFileMetadata,
  metadata,
  isLoading,
  setSelectedFiles,
  selectedFiles,
  setProcessing,
  processing,
  isShowConvertPopup,
  onCloseConvertPopUp,
  selectedRowKeys,
  setSelectedRowKeys
}) => {
  return (
    <>
      <DashFileListing
        setSelectedFiles={setSelectedFiles}
        selectedFiles={selectedFiles}
        onCloseConvertPopUp={onCloseConvertPopUp}
        onFileMetadata={onFileMetadata}
        metadata={metadata}
        isLoading={isLoading}
        setSelectedRowKeys={setSelectedRowKeys}
        selectedRowKeys={selectedRowKeys}
        processing={processing}
        setProcessing={setProcessing}
      />
      {isShowConvertPopup && selectedFiles.length > 0 && (
        <ModalListing
          setSelectedFiles={setSelectedFiles}
          selectedFiles={selectedFiles}
          isShowConvertPopup={isShowConvertPopup}
          onCloseConvertPopUp={onCloseConvertPopUp}
          onFileMetadata={onFileMetadata}
          metadata={metadata}
          setSelectedRowKeys={setSelectedRowKeys}
          isLoading={isLoading}
          setProcessing={setProcessing}
        />
      )}
      {/* <!-- Your Documents End -->
	<!-- Files Convert Start --> */}
      <FileConvert />
      {/* <!-- Files Convert End -->
	<!-- Work Type Start --> */}
      <section className='work_type_sec'>
        <div className='main_container'>
          <div className='work_box_main'>
            <div className='work_box work_box1'>
              <div className='work_detl'>
                <div className='work_icon'>
                  <img src={compress_pdf_icon} alt='Compress PDF' />
                </div>
                <p>Compress PDF</p>
                <div className='work_arrow'>
                  <img src={compress_pdf_right_icon} alt='Right Icon' />
                </div>
              </div>
            </div>
            <div className='work_box work_box2'>
              <div className='work_detl'>
                <div className='work_icon'>
                  <img src={merge_pgf_icon} alt='Merge PDF' />
                </div>
                <p>Merge PDF</p>
                <div className='work_arrow'>
                  <img src={merge_pgf_right_icon} alt='Right Icon' />
                </div>
              </div>
            </div>
            <div className='work_box work_box3'>
              <div className='work_detl'>
                <div className='work_icon'>
                  <img src={split_pdf_icon} alt='Split PDF' />
                </div>
                <p>Split PDF</p>
                <div className='work_arrow'>
                  <img src={split_pdf_right_icon} alt='Right Icon' />
                </div>
              </div>
            </div>
            <div className='work_box work_box4'>
              <div className='work_detl'>
                <div className='work_icon'>
                  <img src={sign_pdf_icon} alt='Sign PDF' />
                </div>
                <p>Sign PDF</p>
                <div className='work_arrow'>
                  <img src={sign_pdf_right_icon} alt='Right Icon' />
                </div>
              </div>
            </div>
            <div className='work_box work_box5'>
              <div className='work_detl'>
                <div className='work_icon'>
                  <img src={protect_pdf_icon} alt='Protect PDF' />
                </div>
                <p>Protect PDF</p>
                <div className='work_arrow'>
                  <img src={protect_pdf_right_icon} alt='Right Icon' />
                </div>
              </div>
            </div>
            <div className='work_box work_box6'>
              <div className='work_detl'>
                <div className='work_icon'>
                  <img src={edit_pdf_icon} alt='Edit a PDF' />
                </div>
                <p>Edit a PDF</p>
                <div className='work_arrow'>
                  <img src={edit_pdf_right_icon} alt='Right Icon' />
                </div>
              </div>
            </div>
            <div className='work_box work_box7'>
              <div className='work_detl'>
                <div className='work_icon'>
                  <img src={rotate_pdf_icon} alt='Rotate' />
                </div>
                <p>Rotate</p>
                <div className='work_arrow'>
                  <img src={rotate_pdf_right_icon} alt='Right Icon' />
                </div>
              </div>
            </div>
            <div className='work_box work_box8'>
              <div className='work_detl'>
                <div className='work_icon'>
                  <img src={remove_pdf_icon} alt='Remove Pages' />
                </div>
                <p>Remove Pages</p>
                <div className='work_arrow'>
                  <img src={remove_pdf_right_icon} alt='Right Icon' />
                </div>
              </div>
            </div>
            <div className='work_box work_box9'>
              <div className='work_detl'>
                <div className='work_icon'>
                  <img src={extract_pdf_icon} alt='Extract PDF' />
                </div>
                <p>Extract PDF</p>
                <div className='work_arrow'>
                  <img src={extract_pdf_right_icon} alt='Right Icon' />
                </div>
              </div>
            </div>
            <div className='work_box work_box10'>
              <div className='work_detl'>
                <div className='work_icon'>
                  <img src={word_pdf_icon} alt='Word to PDF' />
                </div>
                <p>Word to PDF</p>
                <div className='work_arrow'>
                  <img src={word_pdf_right_icon} alt='Right Icon' />
                </div>
              </div>
            </div>
            <div className='work_box work_box11'>
              <div className='work_detl'>
                <div className='work_icon'>
                  <img src={to_word_pdf_icon} alt='PDF to Word' />
                </div>
                <p>PDF to Word</p>
                <div className='work_arrow'>
                  <img src={to_word_pdf_right_icon} alt='Right Icon' />
                </div>
              </div>
            </div>
            <div className='work_box work_box12'>
              <div className='work_detl'>
                <div className='work_icon'>
                  <img src={unlock_pdf_icon} alt='Unlock PDF' />
                </div>
                <p>Unlock PDF</p>
                <div className='work_arrow'>
                  <img src={unlock_pdf_right_icon} alt='Right Icon' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- Work Type End --> */}
    </>
  );
};

export default DashboardComponent;
