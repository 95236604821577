/* eslint-disable react/display-name */
import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { link_icon } from '../../../../../assets/images/dashboard';
import UrlModal from '../../../../modals/urlModal/urlModal';
import { useSession } from '../../../../../context/SessionContext';

const GetURLComponent= forwardRef((props, ref) => {
  const { setIsModalVisible, isModalVisible, isLoading, handleGetURL,onDismiss,uniqueKey="Get From URL" } = props;
  const buttonRef = useRef(null);
  const { moduleRef } = useSession();

  // Expose focus to parent via useImperativeHandle
  useImperativeHandle(ref, () => ({
    focus: () => {
      if (buttonRef.current) {
        buttonRef.current.focus();
      }
    }
  }));

  useEffect(() => {
    moduleRef(uniqueKey, buttonRef);
  }, [uniqueKey, buttonRef]);
  return (
    <>
      <div className='device-icon-inner' onClick={() => onDismiss ? null : setIsModalVisible(true)} style={{ cursor: onDismiss ? 'default' : 'pointer' }}  ref={buttonRef}
        tabIndex={0}
        >
        <img src={link_icon} alt='Link' />
        <span>Get From URL</span>
      </div>
      <UrlModal
        visible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        handleGetURL={handleGetURL}
        isLoading={isLoading}
      />
    </>
  );
});

export default GetURLComponent;
