import React, { useEffect, useState } from 'react';
import useDrivePicker from 'react-google-drive-picker';
import { driveClientId, driveDevelopmentKey } from '../../../../../environment';
import strings from '../../../../../utils/strings.json';
import GoogleDriveComponent from './googleDrive';
import { isValidFilesize } from '../helper';
import Notify from '../../../../common/notify';

const GoogleDrive = (props) => {
  const { setUploadInProgress, setSelectedFiles, onDismiss, uploadFileHandler, setLimitExtendMessage } = props;

  const [openPicker, authResponse] = useDrivePicker();
  const [selectedFile, setSelectedFile] = useState(null);
  const [googleAccessToken, setGoogleAccessToken] = useState();

  useEffect(() => {
    if (authResponse?.access_token && selectedFile) {
      const uploadFilesSequentially = async () => {
        for (const file of selectedFile) {
          await handleUploadFile(file, file.downloadLink);
        }
      };

      uploadFilesSequentially();
    }
  }, [authResponse, selectedFile]);

  useEffect(() => {
    if (authResponse?.access_token) {
      setGoogleAccessToken(authResponse.access_token);
    }
  }, [authResponse]);

  const handleOpenPicker = () => {
    openPicker({
      clientId: driveClientId?.replace(/['",]/g, ''),
      developerKey: driveDevelopmentKey?.replace(/['",]/g, ''),
      viewId: 'DOCS',
      showUploadView: false,
      showUploadFolders: true,
      supportDrives: true,
      token: googleAccessToken,
      multiselect: true,
      callbackFunction: (data) => {
        if (data && data.action === 'cancel') {
          setUploadInProgress(false);
          return;
        }
        if (data && data.docs && data.docs.length > 0) {
          setUploadInProgress(true);
          const newFiles =  isValidFilesize(data.docs);
          setSelectedFiles((prevFiles) => {
            const totalFiles = prevFiles.length + newFiles.length;

            if (totalFiles > 20) {
              setLimitExtendMessage(strings.FILE_LIMIT_ERROR_MESSSGE);
              const availableSlots = 20 - prevFiles.length;
              return [...prevFiles, ...newFiles.slice(0, availableSlots)];
            }

            setLimitExtendMessage('');
            return [...prevFiles, ...newFiles];
          });
          const files = data.docs.map((file) => {
            const downloadLink = `https://www.googleapis.com/drive/v3/files/${file.id}?alt=media`;
            return { ...file, downloadLink };
          });
          setSelectedFile(files); // Set multiple files
          setUploadInProgress(false);
        } else {
          Notify('error', strings.NO_FILE_AND_INVALID_RES, '');
        }
      }
    });
  };

  const handleUploadFile = async (file, downloadLink) => {
    await uploadFileHandler({
      file,
      downloadLink,
      fetchHeaders: { Authorization: `Bearer ${googleAccessToken}` }
    });
  };

  return <GoogleDriveComponent handleOpenPicker={handleOpenPicker} onDismiss={onDismiss} />;
};

export default GoogleDrive;
