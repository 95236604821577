import React, { useEffect } from 'react';
import { hero_banner_pattern } from '../../assets/images/home';
import FileConvert from '../../components/partials/homeComponent/FilesConvert';
import FilesConvertInformativeComponent from '../../components/partials/homeComponent/FilesConvertInformative/filesConvertInformative';
import ConvertAllType from '../../components/partials/convertComponent/ConvertAllType';
import EasySteps from '../../components/partials/homeComponent/EasySteps';

const ConvertFileComponent = () => {

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, []);
  return (
    <>
      {/* <!------------------ Banner Section Start -----------> */}
      <div className='banner-bg-img'>
        <img className='destop-img' src={hero_banner_pattern} alt='Hero Banner' />
      </div>
      <FileConvert />
      {/* <!------------------ Banner Section End -----------> */}
      <EasySteps />
      <ConvertAllType />
      <FilesConvertInformativeComponent />
    </>
  );
};

export default ConvertFileComponent;
