import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Notify from '../../../components/common/notify';
import strings from '../../../utils/strings.json';
import DashFileListingComponent from './dashListing';
import { io } from 'socket.io-client';
import { notification } from 'antd';
import { socket_listen_key } from '../../../environment';
import { fetchFiles, multipleDownload, reconvertFiles, removeFile } from '../../../redux/slice';
const socket = io(socket_listen_key);

const DashFileListing = (props) => {
  const {
    data,
    loading,
    fetchFiles,
    metadata,
    callRemoveFile,
    callReconvertFile,
    error,
    selectedFiles,
    setSelectedFiles,
    onCloseConvertPopUp,
    onFileMetadata,
    isLoading,
    setProcessing,
    selectedRowKeys,
    setSelectedRowKeys,
    processing,
    callMultipleDownload,
    authenticate
  } = props;

  const [page, setPage] = useState(1);
  const [sort, setSort] = useState({ name: 0 });
  const [isConvert, setIsConvert] = useState(false);
  const [search, setSearch] = useState();
  const [openDropdowns, setOpenDropdowns] = useState({});
  const [convertOptions, setConvertOptions] = useState();
  const { _id } = authenticate?.getAuthenticateData?.data || '';
  const limit = 10;

  const handleSearch = (searchValue) => {
    setSearch(searchValue);
  };

  useEffect(() => {
    fetchFiles({ page, limit, sort, search });
    setIsConvert(false);
  }, [page, limit, sort, fetchFiles, search, isConvert]);

  const handleSortChange = (field) => {
    setSort((prevSort) => ({ [field]: prevSort[field] === 1 ? -1 : 1 }));
    setPage(1);
  };

  const paginationProps = {
    current: page,
    pageSize: limit,
    total: data?.data?.metaData.total || 0,
    onChange: (page) => setPage(page)
  };

  const handleDeleteFile = async (deleteFiles) => {
    const deleteFilesResult = await callRemoveFile(deleteFiles);
    const { type, error } = deleteFilesResult;
    if (type === 'file/remove/fulfilled') {
      Notify('success', strings.DELETE_FILE_SUCCESS_MSG, '');
      fetchFiles({ page, limit, sort, search });
    } else {
      Notify('error', error?.message, '');
    }
  };

  const handleReconvert = () => {
    if (selectedFiles.length > 0) {
      onCloseConvertPopUp(true);
    } else {
      Notify('error', strings.NO_FILE_SELECTED, '');
    }
  };

  const triggerDownload = (url) => {
    const link = document.createElement('a');
    link.href = url;
    link.download = '';
    link.target = '_self';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const onDownload = async (urls) => {
    if (selectedFiles.length > 0) {
      const fileIds = selectedFiles.map((file) => file.key);
      const requestData = { filesId: fileIds };
      socket.emit('join', _id);
      socket.off('zip-status');
      const result = await callMultipleDownload(requestData);
      const { type, payload } = result;

      if (type === 'multipleDownload/fulfilled') {
        if (payload.success) {
          triggerDownload(payload.data.fileUrl);
          Notify('success', strings.DOWNLOAD_SUCCESS_MSG, '');
        } else {
          Notify('success', 'We will notify after converting in zip', '');
          socket.on('zip-status', (data) => {   
            if (!data.data.processing) {
              if (data.data.status==='SUCCESS') {
                notification.success({
                  message: 'Success! Your file has been zip and it ready for download.',
                  placement: 'topRight',
                  duration: 5
                });
                const fileUrl = data.data.fileUrl;
                if (fileUrl) {
                  triggerDownload(fileUrl);
                } else {
                  console.error('File URL not found in the response data.');
                }
              } else {
                notification.success({
                  message: 'Oops! There was an error downloading your files. Please try again ',
                  placement: 'topRight',
                  duration: 5
                });
              }
            }
          });
        }
      } else {
        Notify('error', 'Selected files failed to download', '');
      }
    } else if (urls && urls.length > 0) {
      urls.forEach((url) => {
        triggerDownload(url);
        Notify('success', strings.DOWNLOAD_SUCCESS_MSG, '');
      });
    } else {
      Notify('error', strings.FILE_SELECT_ERROR, '');
    }
    setSelectedRowKeys([]);
    setSelectedFiles([]);
  };

  const handleDeleteSelectedFiles = (deleteFile) => {
    if (selectedFiles.length > 0) {
      const fileIds = selectedFiles.map((file) => file.key);
      handleDeleteFile(fileIds);
      setSelectedFiles((prevSelectedFiles) => {
        const updatedFiles = prevSelectedFiles.filter((file) => !fileIds.includes(file.key));
        return updatedFiles;
      });
    } else if (deleteFile) {
      handleDeleteFile(deleteFile);
    } else {
      Notify('error', strings.NO_FILE_SELECTED, '');
    }
  };

  const handleConvert = async (fileId) => {
    try {
      setProcessing((prev) => ({ ...prev, [fileId]: true }));
      const fileConversions = [{ id: fileId, convertTo: convertOptions[fileId] }];
      const response = await callReconvertFile(fileConversions);
      if (response.type === 'file/reconvertFiles/fulfilled') {
        setConvertOptions((prev) => {
          const updatedOptions = { ...prev };
          delete updatedOptions[fileId];
          return updatedOptions;
        });
      }
    } catch (error) {
      console.error('Error during reconversion:', error);
    } finally {
      setProcessing((prev) => ({ ...prev, [fileId]: false }));
    }
    setOpenDropdowns((prevState) => ({
      ...prevState,
      [fileId]: false
    }));
  };

  const toggleDropdown = (fileName) => {
    setOpenDropdowns((prevState) => ({
      ...prevState,
      [fileName]: !prevState[fileName]
    }));
  };

  const handleSelectConvertTo = (fileId, convertToOption) => {
    setConvertOptions((prevOptions) => ({
      ...prevOptions,
      [fileId]: convertToOption?.toLowerCase()
    }));
    toggleDropdown(fileId);
  };

  return (
    <DashFileListingComponent
      data={data}
      metadata={metadata}
      isLoading={isLoading}
      loading={loading}
      paginationProps={paginationProps}
      onSortChange={handleSortChange}
      setSelectedFiles={setSelectedFiles}
      selectedFiles={selectedFiles}
      onSearch={handleSearch}
      search={search}
      error={error}
      onFileMetadata={onFileMetadata}
      processing={processing}
      setSelectedRowKeys={setSelectedRowKeys}
      selectedRowKeys={selectedRowKeys}
      handleSelectConvertTo={handleSelectConvertTo}
      handleConvert={handleConvert}
      handleDeleteSelectedFiles={handleDeleteSelectedFiles}
      handleReconvert={handleReconvert}
      onDownload={onDownload}
      convertOptions={convertOptions}
      openDropdowns={openDropdowns}
      toggleDropdown={toggleDropdown}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    data: state.files.data,
    loading: state.files.loading,
    error: state.files.error,
    metadata: state.metaFiles.data,
    authenticate: state.authenticate
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchFiles: (params) => dispatch(fetchFiles(params)),
  callRemoveFile: (params) => dispatch(removeFile(params)),
  callReconvertFile: (data) => dispatch(reconvertFiles(data)),
  callMultipleDownload: (data) => dispatch(multipleDownload(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(DashFileListing);
