import React from 'react';
import SelectFilesComponent from './selectFiles';
import strings from '../../../../../utils/strings.json';
import { v4 as uuidv4 } from 'uuid';

const SelectFiles = (props) => {
  const {
    selectedFiles,
    setSelectedFiles,
    setLimitExtendMessage,
    setUploadInProgress,
    uploadInProgress,
    uploadFileHandler
  } = props;

  const uploadBrowsedFile = async (fileObj) => {
    const blob = new Blob([fileObj.file], { type: fileObj.file.type });
    const fileToUpload = new File([blob], fileObj.file.name, { type: blob.type });
  
    await uploadFileHandler({
      file: fileObj,
      preparedFile: fileToUpload,
    });
  };

  const handleUpload = async (pendingFiles) => {
    setUploadInProgress(true);
    for (const fileObj of pendingFiles) {
      await uploadBrowsedFile(fileObj);
    }
    setUploadInProgress(false);
  };

  const handleFileChange = (event) => {
    const newFiles = Array.from(event.target.files).map((file) => {
      const status = file.size > 20 * 1024 * 1024 ? strings.FILE_SIZE_EXCEEDS_20_MB : strings.PENDING;

      return {
        file,
        id: uuidv4(),
        status,
        percent: status === strings.PENDING ? 0 : 100
      };
    });

    setSelectedFiles((prevFiles) => {
      const totalFiles = prevFiles.length + newFiles.length;

      if (totalFiles > 20) {
        setLimitExtendMessage(strings.FILE_LIMIT_ERROR_MESSSGE);
        const availableSlots = 20 - prevFiles.length;
        return [...prevFiles, ...newFiles.slice(0, availableSlots)];
      }

      setLimitExtendMessage('');
      return [...prevFiles, ...newFiles];
    });

    // Immediately upload files with the PENDING status
    const pendingFiles = newFiles.filter((file) => file.status === strings.PENDING);
    if (pendingFiles.length > 0 && !uploadInProgress) {
      handleUpload(pendingFiles);
    }

    event.target.value = null; // Reset the file input
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const files = Array.from(event.dataTransfer.files);
    handleFileChange({ target: { files } }); // Pass the files to handleFileChange
  };
  return (
    <SelectFilesComponent
      handleDrop={handleDrop}
      selectedFiles={selectedFiles}
      handleFileChange={handleFileChange}
    />
  );
};

export default SelectFiles;
