import { Spin } from 'antd';
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { Cookies } from 'react-cookie';
import Notify from '../../common/notify';
import { currency, maxEmailLength, paymentElementOptions, validEmail } from '../../../utils/commonUtils';
import './paymentModal.scss';
import { useSession } from '../../../context/SessionContext';
import { useLocation } from 'react-router-dom';
import Modal from '../../common/Modal/modal';
import strings from '../../../utils/strings.json';
import { checkEmailExists, createCustomerSubscription, getPrice, settingBanIp } from '../../../redux/slice';
const PaymentModal = ({
  customer,
  createCustomerSubscription,
  checkoutModalOpen,
  setCheckoutModalOpen,
  getPrice,
  checkEmailExists,
  setShowThankyouPopup,
  callSettingBanIp,
  setSelectedFiles
}) => {
  const [message, setMessage] = useState('');
  const [emailerror, setEmailError] = useState('');
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [priceData, setPriceData] = useState(null);
  const cookies = new Cookies();
  const lang = cookies.get('lang');
  const stripe = useStripe();
  const elements = useElements();
  const { sessionId } = useSession();
  const location = useLocation();
  const isConvertRoute = location.pathname.includes('/convert');
  const params = cookies.get('param');
  const ipData = useSelector((state) => state.getIPIfy.getIPIfyData);
  const { ip } = ipData;
  const clkPath = location.pathname.includes('convert?clk=ae4h3');
  const checkIp = useSelector((state) => state?.checkSettingBanIp?.checkSettingBanIpData.data);
  useEffect(() => {
    const fetchPriceData = async () => {
      const result = await getPrice(currency);
      const { type, payload, error } = result || {};
      if (type === 'getPrice/fulfilled') {
        setPriceData(payload.data);
      } else {
        Notify('error', error.message, '');
      }
    };

    fetchPriceData();
  }, [getPrice]);

  const handleChangeEmail = (e) => {
    setEmail(e.target.value);
    setEmailError('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!email.trim()) {
      setEmailError('Please enter email');
      return;
    } else if (!validEmail.test(email.trim())) {
      setEmailError('Please enter valid email');
      return;
    }

    try {
      if (!stripe || !elements) {
        return;
      }
      setIsLoading(true);
      const response = await checkEmailExists(email.trim().toLowerCase());
      if (response?.type === 'checkEmailExists/fulfilled') {
        const { payload } = response || {};
        const { data, message } = payload || {};
        const { exists } = data;
        if (exists) {
          setMessage(message);
        } else {
          const result = await stripe.confirmPayment({
            elements,
            redirect: 'if_required'
          });
          if (result.error) {
            setMessage(result.error.message);
          } else {
            const apiPayload = {
              email: email.toLowerCase().trim(),
              paymentMethodId: result?.paymentIntent?.payment_method,
              customerId: customer,
              priceId: priceData?.priceId,
              currency,
              language: lang,
              sessionId: sessionId
            };
            const subscriptionResult = await createCustomerSubscription(apiPayload);
            if (subscriptionResult?.type === 'createCustomerSubscription/fulfilled') {
              const { payload } = subscriptionResult || {};
              Notify('success', payload.message, '');
              setSelectedFiles([]);
              const { accessToken } = payload.data;
              cookies.set('token', accessToken);
              setCheckoutModalOpen(false);
              setTimeout(() => {
                setShowThankyouPopup(true);
              }, 2000);
              if (window.location.pathname.toLowerCase().includes('convert')) {
                if (
                  params &&
                  typeof params === 'object' &&
                  !Object.entries(params)
                    .map(([key]) => {
                      if (key === 'off') {
                        return 'off';
                      }
                    })
                    .includes('off')
                ) {
                  cookies.set('banned', 'true');
                  const data = {
                    ipAddress: ip,
                    status: false
                  };
                  await callSettingBanIp(data);
                }
              }
            } else {
              Notify('error', subscriptionResult.error.message, '');
            }
          }
        }
      } else {
        setMessage(response?.error?.message);
      }
    } catch (error) {
      setMessage(error.message);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <>
      <Modal
        isOpen={checkoutModalOpen}
        onClose={() => setCheckoutModalOpen(false)}
        closable
        maskClosable={false}
        className='cardpayment__wrap'
      >
        <Spin spinning={isLoading}>
          <div class='modal_number_info'>
            <div class='login-modal-title'>
              <p>Your file is ready for download</p>
            </div>
          </div>
          <div className='login-modal-section'>
            <div className='wrap-top-modal'>
              <div className='body-modal'>
                <div className='payment-details payment_modal_area'>
                  <div className='df-details'>
                    <div className='left-payment'>
                      <p>Payment</p>
                    </div>
                    <div className='right-payment pay'>
                      <p>₹49.99</p>
                    </div>
                  </div>
                  <div class='right-payment pay'>
                    <span>24h trial, then ₹2495/month after trial</span>
                  </div>
                </div>
                <form id='payment-form' onSubmit={handleSubmit}>
                  <PaymentElement id='payment-element' options={paymentElementOptions} />
                  <div className='payment__modal_input'>
                    <label htmlFor=''>Email Address</label>
                    <input
                      className='email_input'
                      placeholder='hello@mail.com'
                      value={email}
                      onChange={handleChangeEmail}
                      maxLength={maxEmailLength}
                    />
                    {emailerror && <p className='error-message'>{emailerror}</p>}
                  </div>
                  <button
                    id='submit'
                    className='hl_cta_wrap mt-4'
                    type='submit'
                    style={{ width: '100%' }}
                    disabled={isLoading}
                  >
                    <span id='button-text'>Pay and Download</span>
                  </button>
                  {(isConvertRoute === false ||
                    (isConvertRoute === true && checkIp === false && clkPath === false)) && (
                    <span id='sign-up-term'>{strings.TERM}</span>
                  )}
                </form>
                {message && <p className='error-message'>{message}</p>}
              </div>
            </div>
          </div>
        </Spin>
      </Modal>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  createCustomerSubscription: (data) => dispatch(createCustomerSubscription(data)),
  getPrice: (data) => dispatch(getPrice(data)),
  checkEmailExists: (data) => dispatch(checkEmailExists(data)),
  callSettingBanIp: (data) => dispatch(settingBanIp(data))
  // callGetIPIfy: () => dispatch(getIPIfy()),
});

export default connect(null, mapDispatchToProps)(PaymentModal);
